import { mapGetters } from 'vuex';
import { getProductWeight } from '../helpers/product';
import { weightGToKg } from '../filters';

export default {
  name: 'CartValidations',
  computed: {
    ...mapGetters({
      productsInCart: 'cart/getCartItems',
      cartStates: 'cart/getCartStates',
      getCurrent: 'shipping-module/getCurrent',
      isNewPost: 'shipping-module/isCurrentNewPost'
    }),
    totalWeightSummary () {
      const weight = this.productsInCart.reduce((result, product) => {
        return result + (getProductWeight(product) * product.qty);
      }, 0);

      return weightGToKg(weight)
    },
    maxWeightValid () {
      if (this.cartStates.maxWeight.maxWeightToKG === 0) return true

      return this.cartStates.maxWeight.maxWeightToKG >= this.totalWeightSummary
    },
    maxWeightValidationMessage () {
      if (this.maxWeightValid) return null

      const maxWeight = this.cartStates.maxWeight.maxWeightToKG

      if (this.isNewPost) {
        return this.$t(`Your New Post order exceeds {overweigh} ${this.getCurrent.npType}`, {
          overweigh: +((this.totalWeightSummary - maxWeight).toFixed(2))
        })
      }

      return this.$t('Your order exceeds {weight_limit}', {
        weight_limit: maxWeight
      })
    },
    notEnoughValidationMessage () {
      if (!this.cartStates.productStock?.showNotEnoughMessage) return null

      return this.$t('The amount or quantity of your order has changed')
    },
    stateReplacements () {
      return this.cartStates.productStock?.replacements?.length || 0
    },
    replacementsValidationMessage () {
      if (!this.stateReplacements) return null

      return this.$t('Summary not enough {items}', { items: this.$tc('{count} items not available', this.stateReplacements) })
    },
    commonValidation () {
      if (this.maxWeightValid || !this.stateReplacements) return null

      return this.$t('Common error {items} {weight_limit}', {
        items: this.$tc('{count} items not available', this.stateReplacements),
        weight_limit: this.cartStates.maxWeight.maxWeightToKG
      })
    },
    newPostValidation () {
      if (!this.isNewPost) return null

      const item = this.productsInCart.find(i => !i.forNewPost)

      if (!item) return null

      return this.$t('Not available for delivery by Novaya Poshta')
    },
    newPostAlcoholValidation () {
      if (!this.isNewPost) return null

      if (!this.getCurrent?.npShop?.alcohol) return

      const item = this.productsInCart.find(i => i.is_18_plus)

      if (!item) return null

      return this.$t('New post alcohol restriction')
    },
    cartValidationWarning () {
      if (this.notEnoughValidationMessage) {
        return {
          type: 'warnings',
          message: this.notEnoughValidationMessage
        }
      }

      return null;
    },
    cartValidationMessage () {
      if (this.commonValidation) {
        return {
          type: 'common',
          message: this.commonValidation
        }
      }

      if (this.maxWeightValidationMessage) {
        return {
          type: 'max-weight',
          message: this.maxWeightValidationMessage
        }
      }

      if (this.replacementsValidationMessage) {
        return {
          type: 'replacements',
          message: this.replacementsValidationMessage
        }
      }

      if (this.newPostValidation) {
        return {
          type: 'replacements',
          message: this.newPostValidation
        }
      }

      if (this.newPostAlcoholValidation) {
        return {
          type: 'replacements',
          message: this.newPostAlcoholValidation
        }
      }

      return null;
    }
  }
}
