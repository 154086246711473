import UAParser from 'ua-parser-js'

export const getDeviceByUserAgent = (userAgent: string) => {
  const parser = new (UAParser as any)(userAgent)
  const device = parser.getDevice()

  return {
    type: device.type || 'desktop',
    isMobile: device.type === 'mobile',
    isTablet: device.type === 'tablet',
    screenWidth: window.innerWidth
  }
}

export const getDeviceByHeaders = (headers: { [key: string]: any }) => {
  const userAgent = headers['user-agent']
  const parser = new (UAParser as any)(userAgent)
  const isMobileHeader = headers['sec-ch-ua-mobile'] === '?1'

  const device = parser.getDevice()

  return {
    device,
    deviceType: device.type || (isMobileHeader ? 'mobile' : 'desktop'),
    isMobileHeader
  }
}
